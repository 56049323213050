exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-disclaimer-js": () => import("./../../../src/pages/disclaimer.js" /* webpackChunkName: "component---src-pages-disclaimer-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-service-js": () => import("./../../../src/pages/service.js" /* webpackChunkName: "component---src-pages-service-js" */),
  "component---src-pages-service-tab-js": () => import("./../../../src/pages/service_tab.js" /* webpackChunkName: "component---src-pages-service-tab-js" */),
  "component---src-pages-service-tab-service-civil-court-cases-js": () => import("./../../../src/pages/service_tab/Service_CivilCourtCases.js" /* webpackChunkName: "component---src-pages-service-tab-service-civil-court-cases-js" */),
  "component---src-pages-service-tab-service-commercial-court-cases-js": () => import("./../../../src/pages/service_tab/Service_CommercialCourtCases.js" /* webpackChunkName: "component---src-pages-service-tab-service-commercial-court-cases-js" */),
  "component---src-pages-service-tab-service-consumer-court-cases-js": () => import("./../../../src/pages/service_tab/Service_ConsumerCourtCases.js" /* webpackChunkName: "component---src-pages-service-tab-service-consumer-court-cases-js" */),
  "component---src-pages-service-tab-service-criminal-court-cases-js": () => import("./../../../src/pages/service_tab/Service_CriminalCourtCases.js" /* webpackChunkName: "component---src-pages-service-tab-service-criminal-court-cases-js" */),
  "component---src-pages-service-tab-service-high-court-cases-js": () => import("./../../../src/pages/service_tab/Service_HighCourtCases.js" /* webpackChunkName: "component---src-pages-service-tab-service-high-court-cases-js" */),
  "component---src-pages-service-tab-service-mattrimonial-court-cases-js": () => import("./../../../src/pages/service_tab/Service_MattrimonialCourtCases.js" /* webpackChunkName: "component---src-pages-service-tab-service-mattrimonial-court-cases-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */)
}

